import { useMemo } from 'react';

import { defaultValue } from '../pageHelpers';
import { AcademyAnalysisTrainingSessionDashboard } from '../types';
import AnalysisSessionCompletion from './AnalysisSessionCompletion';
import AnalysisSessionStatics from './AnalysisSessionStatistics';
import PlayerCard from './PlayerCard';

interface Props {
  academyAnalysis: AcademyAnalysisTrainingSessionDashboard;
  sessionName: string;
  isLoading: boolean;
}

const CardSection = ({ academyAnalysis, sessionName, isLoading }: Props) => {
  const remainingDrills = useMemo(() => {
    if (
      !academyAnalysis ||
      !academyAnalysis.sessionData ||
      !academyAnalysis.sessionData.analysisStatistics
    ) {
      return {
        drillsRemaining: defaultValue,
        remainingDrillsPercentage: defaultValue,
      };
    }

    const allDrillsCount =
      academyAnalysis.sessionData.playersCount *
      academyAnalysis.sessionData.drillsInSession;

    const drillsRemaining = Math.max(
      defaultValue,
      allDrillsCount -
        academyAnalysis.sessionData.analysisStatistics.drillsComplete
    );

    const remainingDrillsPercentage =
      academyAnalysis.sessionData.analysisStatistics.drillsComplete > 0 ||
      allDrillsCount > 0
        ? Math.min(
            100,
            Math.floor(
              (academyAnalysis.sessionData.analysisStatistics.drillsComplete /
                allDrillsCount) *
                100
            )
          )
        : defaultValue;

    return {
      drillsRemaining,
      remainingDrillsPercentage,
    };
  }, [academyAnalysis]);

  return (
    <>
      <AnalysisSessionStatics
        isDataFetching={isLoading}
        academyAnalysis={academyAnalysis}
      />

      <AnalysisSessionCompletion
        isDataFetching={isLoading}
        remainingDrillsData={remainingDrills}
      />

      <PlayerCard
        isDataFetching={isLoading}
        academyAnalysis={academyAnalysis}
        session={sessionName}
      />
    </>
  );
};

export default CardSection;
