import { Box, Divider, Grid, Stack } from '@mui/material';

import commonStyles from '../commonStyles';
import { defaultValue } from '../pageHelpers';
import { AcademyAnalysisTrainingSessionDashboard } from '../types';
import AnalysisSessionSection from './AnalysisSessionSection';
import ContentContainer from 'components/shared/ContentContainer';

interface Props {
  isDataFetching: boolean;
  academyAnalysis: AcademyAnalysisTrainingSessionDashboard | undefined;
}

const AnalysisSessionStatics = ({ isDataFetching, academyAnalysis }: Props) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
    <ContentContainer
      containerTitle="Analysis Session Statistics"
      sx={commonStyles.content}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        pt={5}
      >
        <AnalysisSessionSection
          label="Players Analysed"
          isDataFetching={isDataFetching}
          count={
            academyAnalysis?.sessionData?.analysisStatistics?.playersAnalysed ??
            defaultValue
          }
        />

        <Box px={10}>
          <Divider sx={commonStyles.divider} orientation="vertical" />
        </Box>

        <AnalysisSessionSection
          label="Drills Complete"
          isDataFetching={isDataFetching}
          count={
            academyAnalysis?.sessionData?.analysisStatistics?.drillsComplete ??
            defaultValue
          }
        />
      </Stack>
    </ContentContainer>
  </Grid>
);

export default AnalysisSessionStatics;
