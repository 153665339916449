import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { defaultValue } from '../pageHelpers';
import { AcademyAnalysisTrainingSessionDashboard } from '../types';
import ContentContainer from 'components/shared/ContentContainer';

interface Props {
  isDataFetching: boolean;
  session: string | null;
  academyAnalysis: AcademyAnalysisTrainingSessionDashboard | undefined;
}

const customStyles = {
  playerCard: {
    backgroundColor: '#24D86C',
    height: '100%',
  },
  trialGroupName: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  smallFont: {
    fontSize: 14,
  },
  containerTitle: {
    width: {
      xs: '40vw',
      md: '12vw',
    },
    height: 40,
  },
  containerOptions: {
    width: {
      xs: '10vw',
      md: '4vw',
    },
    mt: -2,
    height: 40,
  },
};

const PlayerCard = ({ isDataFetching, session, academyAnalysis }: Props) => (
  <Grid item xs={12} md={4} lg={4} xl={4}>
    <ContentContainer
      sx={customStyles.playerCard}
      containerTitle={
        isDataFetching ? (
          <Skeleton sx={customStyles.containerTitle} />
        ) : (
          <Box sx={customStyles.trialGroupName} component="span">
            {session}
          </Box>
        )
      }
      containerRight={
        isDataFetching ? (
          <Skeleton sx={customStyles.containerOptions} />
        ) : (
          <Typography variant="h3">
            {`${
              academyAnalysis?.sessionData?.playersCount ?? defaultValue
            } Players`}
          </Typography>
        )
      }
    />
  </Grid>
);

export default PlayerCard;
